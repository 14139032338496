<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on">
          <v-avatar size="30px" item class="ma-2">
            <avatar-icon
              :username="user['custom:name']"
              :size="32"
              color="white"
              backgroundColor="#114cac"
            />
          </v-avatar>
          <div class="header">{{ user['custom:name'] }}</div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="item.click"
        >
          <v-list-item-title
            ><v-icon>{{ item.icon }}</v-icon>
            {{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AvatarIcon from 'vue-avatar'

export default {
  data: (vm) => ({
    items: [
      { title: 'My Profile', icon: 'mdi-account', click: () => null },
      { title: 'Settings', icon: 'mdi-cog', click: () => null },
      { title: 'Logout', icon: 'mdi-login', click: vm.onLogout },
    ],
  }),
  components: { AvatarIcon },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async onLogout() {
      await this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({ name: 'SignIn' })
      })
    },
  },
}
</script>
